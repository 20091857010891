<script>
import PageHeader from "@/components/page-header";
import Filters from "@/components/filter";
import Kpis from "@/components/kpis";
import RatingComparison from "./rating-comparison";
import OverallChart from "@/components/overall-chart";
import KPIBlocks from "./kpi-blocks";
import DataUtils from "@/js/data-utils";

export default {
  data() {
    return {
      loading: false,
			filterCols: ["group_companies", "companies", "daterange"],
      kpiCols:    ["rating", "sentiment", "tes", "review_count"],
      chartCols:  ["reviews_count", "numerical_rating", "sentiment_rating", "tes_score"],
      filterParams: {}
    };
  },
  components: {
    PageHeader,
    Filters,
    Kpis,
    RatingComparison,
    KPIBlocks,
    OverallChart
  },
  methods: { ...DataUtils }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('daily_operations.dashboard.title')" />

    <div class="row">
      <div class="col-lg-12">
        <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" :cols="filterCols" :preventSubmit="true" />

        <KPIBlocks v-show=filterParams.data :filterParams=filterParams />

        <div class="row">
          <div class="col-lg-12">
            <div v-if="!filterParams.data" class="card bg-light select-group-company">{{ $t("general.select_group_or_company") }}</div>
            <OverallChart v-show="filterParams.data" :filterParams="filterParams" :cols="chartCols" page="reputation_dashboard"/>
          </div>
        </div>

        <Kpis v-show="filterParams.data" :filterParams="filterParams" :cols="kpiCols" countslug="number_reviews" dashboard_type="reputation" />

        <div class="row">
          <div class="col-xl-12">
            <RatingComparison v-show="filterParams.data" :filterParams="filterParams" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
