<script>
import axios from "axios"
import Utils from "@/js/utils";
import dayjs from "dayjs";
import Chart from "./chart";
import Table from "./table";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      loading: true,
      data: {
        numerical: { own: { group: [], property: [] }, competitors: { group: [], property: [] } },
        sentiment: { own: { group: [], property: [] }, competitors: { group: [], property: [] } },
        tes:       { own: { group: [], property: [] }, competitors: { group: [], property: [] } },
      },
      series: { numerical: {}, sentiment: {}, tes: {} },
      labels: [],
      ratingHash: {
        numerical: "ratings",
        sentiment: "sentiment",
        tes:       "tes"
      },
      valueHash: {
        numerical: "value",
        sentiment: "sentiment_score",
        tes:       "score"
      },
      parameters: { own: {}, competitors: {} },
      colors: ["#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
      chartTypeOptions: ["line", "table"],
      chartType: "line",
      comparisonOptions: ["ratings", "competitors"],
      comparisonType: "ratings",
      period: "month",
      periodOptions: {
        day:     "DD",
        week:    "DD MMM YY",
        month:   "MMMM YYYY",
        quarter: "[Q]Q YYYY",
        year:    "YYYY"
      }
    }
  },
  props: ["ratingType", "filterParams", "companyNames", "competitorsColors"],
  components: { Chart, Table, Multiselect },
  watch: {
    filterParams: {
      handler: function () {
        if (this.ratingType && this.filterParams.data) this.loadListener(this.filterParams);
      },
      immediate: true
    },
    period: {
      handler: function () {
        this.loadListener(this.filterParams);
      }
    }
  },
  methods: {
    loadListener (params) {
      Utils.setLoading.bind(this)(true);
      let group_ids = Utils.flattenGroupIds(params.data.group_ids);

      this.parameters.own = {
        per:         this.period,
        company_ids:  params.data.company_ids || [], group_ids,
        ...Utils.slice(params.data, ["start_date", "end_date", "sources", "exclude_sources"]),
        countries:    params.data.reviewer_country,
        languages:    params.data.language,
        compositions: params.data.travel_composition
      };
      this.parameters.competitors = {
        ...this.parameters.own,
        company_ids:  params.competitors?.map(c => c.id) || [], group_ids,
        sources:      params.benchmark.sources,
        countries:    params.benchmark.reviewer_country,
        languages:    params.benchmark.language,
        compositions: params.benchmark.travel_composition,
        exclude_sources: params.benchmark.exclude_sources
      }

      this.clearData();
      this.load();
    },
    async load() {
      let ratingType = this.ratingHash[this.ratingType];

      await this.loadData(ratingType, "company_ids", "group_ids");   // companies and competitors
      await this.loadData(ratingType, "group_ids", "company_ids");   // groups and competitors

      this.labels = this.sortByDate([...new Set(this.labels)]);
      this.fillUndefined();

      Utils.setLoading.bind(this)(false);
    },
    async loadData(ratingType, level, antiLevel) {
      let levelKey = level == "company_ids" ? "property" : "group";
      let params = this.parameters.own;
      let promises = [];

      // selected group_ids / company_ids
      params[level].forEach(id => {
        promises.push(axios.get(`/v3/${ratingType}/stats`, { params: { ...params, [antiLevel]: null, [level]: id, segment: "ratings" } }).then(response => {
          this.data[this.ratingType].own[levelKey] = this.sortByDate(response.data.data);
          this.formatRatings("own", levelKey, id);
        }));
      });

      await Promise.all(promises);

      // competitors
      params = this.parameters.competitors;
      promises = [];
      params[level].forEach(id => {
        promises.push(axios.get(`/v3/${ratingType}/stats`, { params: { ...params, [antiLevel]: null, [level]: id, competitors: levelKey == "group", segment: "ratings" } }).then(response => {
          this.data[this.ratingType].competitors[levelKey] = this.sortByDate(response.data.data);
          this.formatRatings("competitors", levelKey, id);
        }));
      });

      await Promise.all(promises);
    },
    formatRatings(belongsTo, levelKey, propertyId) {
      let dataField    = this.ratingType == "sentiment" ? "opinions" : this.ratingHash[this.ratingType];
      let valueField   = this.valueHash[this.ratingType];
      let propertyName = this.companyNames[propertyId];

      if (levelKey == "group" && belongsTo == "competitors") {
        propertyName = this.$t("daily_operations.analytics.group_competitors", { name: propertyName });
        propertyId   = `group_competitor_${propertyId}`;
      }

      this.data[this.ratingType][belongsTo][levelKey].forEach(data => {
        this.labels.push(data.date);
        data[dataField].forEach(rating => {
          if (!rating.topic) return;
          let series = this.series[this.ratingType][rating.topic]?.[propertyId];
          let value  = rating[valueField];
          if (value) value = Utils.round(this.ratingType == "numerical" ? rating[valueField] / 10 : rating[valueField]);

          if (series) { // if pair topic - property already registered in the series hash
            this.series[this.ratingType][rating.topic][propertyId] = {
              ...series,
              values: [...series.values, value || null], labels: [...series.labels, data.date],
              review_count: [...series.review_count, rating.review_count]
            };
          } else {
            if (!this.series[this.ratingType][rating.topic]) {
              let color = this.colors.shift();
              this.colors.push(color);
              this.series[this.ratingType][rating.topic] = { hidden: true, color };
            }
            else {
              this.series[this.ratingType][rating.topic] = { ...this.series[this.ratingType][rating.topic] };
            }
            this.series[this.ratingType][rating.topic][propertyId] = {
              values: [value || null], labels: [data.date],
              review_count: [rating.review_count],
              name: `${this.$t(`ratings.rating_comparison.kpis.${rating.topic}`)} - ${propertyName}`,
              company: { name: propertyName, id: propertyId }, belongsTo, topic: rating.topic
            };
          }
        })
      });
    },
    fillUndefined() {
      Object.keys(this.series[this.ratingType]).forEach((topic, index) => {
        if (index < 3) this.series[this.ratingType][topic].hidden = false;
        Object.keys(this.series[this.ratingType][topic]).forEach(property => {
          if (/hidden|color/.test(property)) return;
          let labels = this.series[this.ratingType][topic][property].labels;
          if (labels.length != this.labels.length) {
            this.labels.forEach((l, i) => {
              if (!labels.includes(l)) {
                labels.splice(i, 0, l);
                this.series[this.ratingType][topic][property].values.splice(i, 0, null);
                this.series[this.ratingType][topic][property].review_count.splice(i, 0, null);
              }
            });
          }
        });
      });
    },
    clearData() {
      this.labels = [];
      this.data[this.ratingType] = { own: { group: [], property: [] }, competitors: { group: [], property: [] } };
      this.series[this.ratingType] = {};
    },
    sortByDate(data) {
      return data.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
    }
  },
  computed: {
    showChart() {
      return Object.keys(this.series[this.ratingType]).length;
    },
    title() {
      if (this.comparisonType == "competitors") return this.$t("daily_operations.analytics.competitors_comparison");
      return this.$t("daily_operations.analytics.ratings_comparison");
    },
    formattedLabels() {
      return this.labels.map(l => dayjs(l).format(this.periodOptions[this.period]));
    }
  }
};
</script>

<template>
  <div class="card" :class="{'loading': loading}">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between row">
        <div class="col-sm-12 col-lg-auto col-xxl-6">
          <h4 class="mb-1 float-start">{{ title }}</h4>
          <span class="ms-1 clearfix" v-b-tooltip.hover :title="$t('daily_operations.analytics.overall_help')">
            <i class="mdi mdi-help-circle"></i>
          </span>
        </div>

        <div class="col-sm-12 mt-3 mt-xl-0 col-xl-auto col-xxl-6 row">
          <!-- chart type -->
          <div class="col-12 col-md-4">
            <label class="form-label mb-1">{{ $t("reports.settings_modal.period") }}</label>
            <multiselect class="w-75" :multiple="false" v-model="period" :options="Object.keys(periodOptions)" :showLabels="false" :custom-label="p => $t(`reports.periods.${p}`)" :allow-empty="false" style="min-width: 150px !important;"></multiselect>
          </div>

          <!-- chart type -->
          <div class="col-12 col-md-4">
            <label class="form-label mb-1">{{ $t("daily_operations.analytics.chart_type") }}</label>
            <multiselect class="w-75" :multiple="false" v-model="chartType" :options="chartTypeOptions" :showLabels="false" :custom-label="l => $t(`daily_operations.analytics.${l}`)" :allow-empty="false" style="min-width: 150px !important;"></multiselect>
          </div>

          <!-- type of chart/table -->
          <div class="col-12 col-md-4">
            <label class="form-label mb-1">{{ $t("daily_operations.analytics.compare") }}</label>
            <multiselect class="w-75" :multiple="false" v-model="comparisonType" :options="comparisonOptions" :showLabels="false" :custom-label="l => $t(`daily_operations.analytics.${l}`)" :allow-empty="false" style="min-width: 150px !important;"></multiselect>
          </div>
        </div>
      </div>

      <div v-if="showChart && !loading">
        <Chart v-if="chartType == 'line'" :series="series[ratingType]" :comparisonType="comparisonType" :labels="formattedLabels" :colors="colors" :competitorsColors="competitorsColors" />
        <Table v-else :series="series[ratingType]" :comparisonType="comparisonType" :keys="labels" :labels="formattedLabels"  :period="period" :periodOptions="periodOptions" />
      </div>

      <div class="mt-5" v-if="!showChart">{{ $t("general.no_data") }}</div>
    </div>
  </div>
</template>
