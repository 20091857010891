<script>
export default {
  data() {
    return {
      map: null
    };
  },
  props: ["initOpts", "defaults"],
  mounted() {
    this.initMap();
  },
  beforeUnmount() {
    this.map.remove();
  },
  methods: {
    initMap() {
      const options = {
        ...this.initOpts,
        container: "placeholder-map",
        center: [this.defaults.lng, this.defaults.lat],
        zoom:   2
      };
      this.map = new window.maplibregl.Map(options);
    }
  }
}
</script>

<template>
  <div id="placeholder-map" class="map"></div>
</template>
