<script>
import FeedbackUtils from "@/js/feedback-utils";

export default {
  data() {
    return {
      yesNoOptions: { true: "yes", false: "no" },
      polarityClass: {
        positive: { icon: "fa-smile text-success", tag: "bg-success"   },
        neutral:  { icon: "fa-meh text-secondary", tag: "bg-secondary" },
        negative: { icon: "fa-frown text-danger",  tag: "bg-danger"    }
      },
    };
  },
  props: ["answer", "response", "params", "responsesCache", "respIndex", "translations", "translated", "polaritiesToShow"],
  methods: {
    ...FeedbackUtils,
    // Load question translations
    findTranslation(questionTranslations, answerLocale) {
      if (questionTranslations[this.$i18n.locale]) return questionTranslations[this.$i18n.locale];
      if (answerLocale?.code) return questionTranslations[answerLocale.code];
      if (this.response.additional_info["guest-language"]) return questionTranslations[this.response.additional_info["guest-language"]];
      return questionTranslations["en"] || Object.values(questionTranslations)[0];
    },
    range(size, startAt = 0) {
      size -= startAt
      return [...Array(size).keys()].map(i => i + startAt);
    },
    usePolarity(opinions, polarity) {
      return opinions?.find(e => e.polarity == polarity)?.properties;
    }
  }
}
</script>

<template>
  <div class="question-answer mb-4" v-if="response">
    <h5 class="question-title mb-1">
      {{ replaceCompanyName(findTranslation(answer.question.texts.question, response.language), response.company.name) }}
    </h5>
    <template v-if="answer.type == 'multiple_select'">
      <div class="answer" >
        <ul class="custom-ul mb-1" v-for='v in answer.selection' :key='String(v)'>
          <li v-html='getAnswer(v, answer.type, false, answer.question.options )'></li>
        </ul>
      </div>
    </template>
    <template v-else>
      <div v-if='answer.type == "rating"' class="d-flex flex-wrap align-items-center font-size-14 rating-color">
        <div class="d-flex mt-1 mb-1 me-3 flex-wrap">
          <span v-for='i in range(findAnswer(answer))' class='star-rate ion ion-md-star me-2' :key='i'></span>
          <span v-for='i in range(answer.question.max_value, findAnswer(answer))' class='star-rate ion ion-md-star-outline me-2' :key='i'></span>
        </div>
        <div>{{ findAnswer(answer)}} / {{ answer.question.max_value }}</div>

      </div>
      <div v-else-if='answer.type == "nps"' class="d-flex flex-wrap align-items-center font-size-14" :style="`color: ${NPS(findAnswer(answer)).color};`">
        <div class="d-flex mt-1 mb-1 me-3 flex-wrap">
          <span v-for='i in range(findAnswer(answer))' class='star-rate ion ion-md-star me-2' :key='i'></span>
          <span v-for='i in range(answer.question.max_value, findAnswer(answer))' class='star-rate ion ion-md-star-outline me-2' :key='i'></span>
        </div>
        <div>{{ findAnswer(answer) }} / {{ answer.question.max_value }}</div>
        <div class="ms-3">{{ $t(`surveys_responses.${NPS(findAnswer(answer)).label}`) }}</div>
      </div>
      <template v-else>
        <div class='answer' v-html='getAnswer(findAnswer(answer), answer.type, translated, answer.question.options)'></div>
      </template>
    </template>

    <!-- Follow Up -->
    <div class="answer-follow-up mb-3" v-if="answer.follow_up">
      <strong>{{ $t("surveys_responses.follow_up") }}: </strong>
      {{ translated && translations[$i18n.locale][answer.id] || answer.follow_up }}
    </div>

    <!-- Sentiment -->
    <template v-if="answer.sentiment && answer.sentiment.filteredOpinions.length">
      <h4 class="question-title mt-3 mb-3 ps-2"> {{ $t("surveys_responses.sentiments") }} </h4>
      <div class="d-flex flex-column justify-content-start ps-2">
        <template v-for="p in polaritiesToShow" :key="p">
          <div class="me-4 mb-2" :class="p" v-if="usePolarity(answer.sentiment.filteredOpinions, p)">
            <div class="d-flex">
              <i class="fa font-size-22 me-2" :class="polarityClass[p].icon" v-b-tooltip.hover :title="$t(`surveys_responses.${p}_sentiment`)"></i>
              <div class="d-flex flex-wrap" style="gap: 7px;">
                <div class="tag text-center" :class="polarityClass[p].tag" v-for="(property, index) in usePolarity(answer.sentiment.filteredOpinions, p)" :key="property + index">
                  {{ $t(`topics.${property}`) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
