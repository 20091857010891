<script>
import PopUp from "./popup";

export default {
  data() {
    return {
      map: null,
      heatmapThreshold: 15,
      popupData: {}
    }
  },
  props: ["data", "initOpts", "position", "heatColors", "defaults"],
  components: { PopUp },
  watch: {
    data: function() {
      const points = {
        type:     "FeatureCollection",
        features: this.generatePoints()
      }
      this.map.getSource("property-heatmap")?.setData(points);
    }
  },
  async mounted() {
    await this.loadPoints();
  },
  beforeUnmount() {
    this.map.remove();
  },
  methods: {
    async loadPoints() {
      const points = this.generatePoints();
      const options = {
        ...this.initOpts,
        container: "concentration-map",
        center: (this.position.lng && this.position.lat) ? [this.position.lng, this.position.lat] : this.getCenter(this.data.map(d => [d.longitude, d.latitude])),
        zoom: this.position.zoom || this.defaults.zoom
      };

      this.map = new window.maplibregl.Map(options);

      this.map.on("load", async () => {
        this.map.addControl(new window.maplibregl.NavigationControl(), "bottom-right");

        this.map.loadImage("https://dashboard-assets.olery.com/img/point.png", (error, image) => {
          if (error) console.error(error);
          this.map.addImage("point", image, { pixelRatio: 2 });
        });

        this.map.addSource("property-heatmap", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: points
          }
        });

        this.map.addLayer({
          id:      "property-point",
          type:    "symbol",
          source:  "property-heatmap",
          minzoom: this.heatmapThreshold,
          layout: {
            "icon-image": "point",
            "icon-size":   0.09,
            "icon-allow-overlap": true
          }
        });

        this.map.addLayer({
          id:      "property-heat",
          type:    "heatmap",
          source:  "property-heatmap",
          maxzoom: this.heatmapThreshold,
          paint: {
            // "heatmap-weight": [
            //   "interpolate",
            //   ["linear"],
            //   ["get", "review_count"],
            //   1,  1,
            //   10, 5,
            // ],
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0, 0,
              1, 0.9
            ],
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              ...this.generateHeatColors()
            ],
            // "heatmap-radius": [
            //   "interpolate",
            //   ["exponential", 0.3],
            //   ["get", "weight"],
            //   0,  100,
            //   10, 20
            // ],
          }
        });

        this.map.on("mouseenter", "property-point", () => {
          this.map.getCanvas().style.cursor = "pointer";
        });

        this.map.on("mouseleave", "property-point", () => {
          this.map.getCanvas().style.cursor = "";
        });

        this.map.on("move", () => this.$emit("updatePosition", { center: this.map.getCenter(), zoom: this.map.getZoom() }));

        this.map.on("click", "property-point", e => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const data = e.features[0].properties;
          this.popupData = { ...data };
          const popup = this.$refs.popup.$el;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new window.maplibregl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(popup)
            .addTo(this.map);
        });
      });
    },
    generatePoints() {
      return this.data.map(d => ({
        type: "Feature",
        properties: {
          radius: d.radius || 0,
          weight: d.weight,
          name:   d.name,
          rating: d.rating,
          review_count: d.quantity,
          numerical: d.numerical,
          sentiment: d.sentiment,
          response_rate: d.response_rate
        },
        geometry: {
          type: "Point",
          coordinates: [d.longitude, d.latitude]
        }
      }));
    },
    generateHeatColors() {
      return Object.entries(this.heatColors).flatMap(([val, color]) => [parseFloat(val), color]);
    },
    getExponentialValue(value) {
      return (value/10)**(2);
    },
    getCenter(points) {
      if (points.length <= 1) return [this.defaults.lng, this.defaults.lat];
      let llb = new window.maplibregl.LngLatBounds(points);
      const { lng, lat } = llb.getCenter();
      return [lng, lat];
    }
  },
  computed: {
    mapAndImg() {
      return this.img && this.map;
    }
  }
}
</script>

<template>
  <div>
    <div id="concentration-map" class="map"></div>
    <PopUp ref="popup" :data="popupData" />
  </div>
</template>
