<script>
import Multiselect from "vue-multiselect";
import { Field }   from "vee-validate";
import sources     from "@/data/sources";
import Utils       from "@/js/utils";

import SettingsModalBenchmark from "@/components/reports/settings-modal-benchmark";
import SettingsModalHtml      from "./settings-modal-html.vue";
import { ColorPicker }        from "vue3-colorpicker";


export default {
  data() {
    return {
      periodsOptions:     [ 'day', 'week', 'month', 'quarter', 'year' ],
      segmentOptions:     ['numerical_rating', 'sentiment_rating', 'tes_rating', 'reviews_count', 'mentions_count', 'responses_count', 'composition', 'country', 'language', 'source', 'travel_type', 'property_type', 'response_rate', 'respondable_responses', 'subratings'],
      nGroups:            50,
      nBenchmarks:        1,
      chart_types:        [ 'column', 'line' ],
      row_types:          [ 'companies', 'groups', 'segments' ],
      propertyTypes:      ['accommodation', 'attraction', 'restaurant', 'airline', 'airport' ],
      groupsCompanies:    {},
      segments:           {},
      baseRatings:        ["overall", "fnb", "service", "cleanliness", "facilities", "value", "location", "ambience", "room"],
      sentimentExclusive: ["sustainable_travel", "sanitary_safety", "health_precautions"],
      showAddColumn:      false,
      edittedColumn:      null,
      orderOptions:       ['asc', 'desc'],
      groups:             [],
      companies:          [],
      surveys:            [],
      contractSources:    [],
      dataToShowOptions:  ['numerical_rating', 'sentiment_rating', 'tes_rating', 'reviews_count']
    }
  },
  props: ['fields', 'settings', 'template', 'modelValue'],
  components: { Multiselect, ColorPicker, Field, SettingsModalHtml, SettingsModalBenchmark },
  methods: {
    loadCompanies () {
      return this.$store.dispatch("companies/fetch", { contract_id: this.contract.id }).then(companies => {
        this.companies = companies
      });
    },
    loadGroups () {
      return this.$store.dispatch("groups/fetch", { contract_id: this.contract.id }).then(groups => {
        this.groups = groups
      });
    },
    loadSurveys() {
      this.$store.dispatch("surveys/fetch", { contract_id: this.contract.id }).then(surveys => {
        this.surveys = Utils.groupByUniq(surveys, 'id');
      });
    },
    updateHtmlValue(value) {
      this.settings.html = value;
    },
    loadData() {
      this.$store.dispatch("filterData/fetch", { contract_id: this.contract.id }).then(data => {
        this.segments = Utils.deepClone(data);
        // this.segments.composition = data.compositions;
        // this.segments.country     = data.countries;
        // this.segments.language    = data.languages;
        this.contractSources = Utils.uniqSorted(Object.values(data.property_types).flatMap(p => p.sources).filter(n => n));
      })
    },
    segmentOptionLabel(value, segment) {
      if (segment == "country")  return this.$t(`countries.${value}`);
      if (segment == "language") return this.$t(`locales.${value}`);
      if (segment == "source")   return this.sourceLabel(value);
      if (segment == "property_type")  return this.$t(`property_types.${value}`);
      if (/tes_rating|numerical_rating|sentiment_rating|reviews_count|mentions_count|responses_count|response_rate/.test(segment))
        return this.$t(`ratings.rating_comparison.kpis.${value}`);
      return value;
    },
    sourceLabel(source) {
      return sources[source]?.friendly_name || source;
    },
    clearSegmentOptionSelection() {
      this.settings.segmentOption = null;
    },
    benchCondition(index) {
      return (
        (this.settings.bench_group_ids && this.settings.bench_company_ids) &&
        (index == 0 || this.settings.bench_group_ids[index - 1] || this.settings.bench_company_ids[index - 1])
        && (this.groups[index] || this.companies[index])
      )
    },
    addChartData() {
      this.settings.chart_data.push({})
    },
    removeChartData(index) {
      this.settings.chart_data.splice(index, 1);
    },
    addColumn() {
      this.edittedColumn = { highlight_from: 8 }
      this.$nextTick(() => { this.showAddColumn = true })
    },
    editColumn(column, colIndex) {
      this.edittedColumn = { ...Utils.deepClone({ ...column, highlight_from: column.highlight_from || 8 }), colIndex }
      this.$nextTick(() => { this.showAddColumn = true })
    },
    removeColumn(column, colIndex) {
      this.settings.columns.splice(colIndex, 1)
    },
    saveColumn() {
      const col   = Utils.deepClone(this.edittedColumn);
      const index = col.colIndex;

      if (index >= 0) this.settings.columns[index] = col;
      else this.settings.columns.push(col);

      this.edittedColumn = null;
      this.showAddColumn = false;
    },
    cancelAddColumn () {
      this.showAddColumn = false;
      this.edittedColumn = null
    },
    orderByLabels (c) {
      if (c == 'header') return this.$t(`reports.based_on_opts.${this.settings.row_data}`)
      return this.$t('reports.settings_modal.segment_options.'+ c)
    },
    benchNames (column) {
      const kpiKeys = { companies: 'company_ids', groups: 'group_ids' }
      return column[`bench_${kpiKeys[column.benchmark_based_on]}`].map(id => this[column.benchmark_based_on]?.find(o => o.id == Number(id))?.name || id).join(', ')
    },
    segmentOptionList(segment) {
      if (!segment) return [];
      if (/survey/.test(segment)) return [];
      if (/tes_rating|numerical_rating|sentiment_rating|reviews_count|mentions_count/.test(segment)) {
        if (/sentiment_rating|mentions_count/.test(segment)) return this.baseRatings.concat(this.sentimentExclusive);
        return this.baseRatings;
      }
      return this.segments[segment] || [];
    },
    save() {
      this.$emit("save");
      this.close();
    },
    close() {
      this.$emit("update:modelValue", false);
    }
  },
  created() {
    if (this.fields.keys.includes('chart_data')) {
      this.settings.chart_data ??= [{}];
    }
    if (this.fields.keys.includes('columns')){
      this.settings.columns ??= [];
    }
    if (this.fields.keys.includes('custom_labels')) {
      if (this.settings.labels && Array.isArray(this.settings.labels)) this.settings.labels = {};
      this.settings.labels ??= {};
    }
    if (this.fields.keys.includes('select_columns') && this.settings.columns == undefined) {
      this.settings.shownColumns = this.fields.options.columns.reduce((obj, k) => { obj[k] = true; return obj }, {})
    }
    if (this.fields.keys.includes('filters')) {
      this.loadData()
      this.settings.filters ??= {}
      this.fields.options.filterCols.forEach(col => this.settings.filters[col] ??= [])
    }
  },
  mounted() {
    if (this.fields.options?.segments?.length) this.segmentOptions   = this.fields.options.segments;
    if (this.fields.keys.includes("order_by") && this.fields.options?.columns?.length)  this.settings.columns = this.fields.options.columns.map(c => ({ type: c }));
    if (this.fields.keys.includes("select_surveys")) this.loadSurveys();
    this.loadCompanies()
    this.loadGroups()
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    columnSegmentOptions() {
      if (this.settings.row_data == "segments" && this.settings.segment == "sentiment_rating") return this.segmentOptions.concat(["sentiment_count"]);
      if (this.settings.row_data == "segments") return this.segmentOptions.filter(s => !/composition|country|language|source|travel_type|subratings/.test(s));
      return this.segmentOptions;
    },
    numberOfGroups () {
      return this.fields.options?.n_segments || this.nGroups;
    },
    columnTypes () {
      const cols = this.settings.columns.map(c => c.type)
      if (this.fields.keys.includes('columns')) return ['header'].concat(cols)
      return cols
    },
    customLabelPlaceholder() {
      if (!this.edittedColumn.type) return this.$t("reports.settings_modal.custom_label");
      return this.$t(`reports.settings_modal.segment_options.${this.edittedColumn.type}`)
    },
    surveysOptions() {
      return Object.keys(this.surveys);
    },
    heightRules() {
      const rules = [];
      if (this.fields.options?.requireHeight) rules.push("required");
      if (this.fields.options?.minHeight)     rules.push(`min_value:${this.fields.options.minHeight}`);
      if (this.fields.options?.maxHeight)     rules.push(`max_value:${this.fields.options.maxHeight}`);
      return rules.join("|");
    },
    periodOptions() {
      const periodIndex = this.periodsOptions.findIndex(p => p == this.template.period);
      return this.periodsOptions.slice(0, periodIndex);
    },
    scores() {
      return Utils.scores;
    },
    orderByOptions() {
      const col = this.settings.columns?.find(c => c.type == this.settings.order_by);
      if (col && /composition|country|language|source|travel_type|subratings/.test(col.type)) return col.data_to_show || [];
      return [];
    },
    highlightList() {
      return (this.edittedColumn.data_to_show || []).filter(l => /_rating/.test(l));
    },
    validFields() {
      const fields = this.fields.keys;
      for (const f of ["period", "heading", "text", "largetext", "html", "image_url", "row_data"]) {
        if (fields.includes(f) && !this.settings[f]) return false;
      }
      if (this.settings.show_title && !this.settings.title) return false;
      if (fields.includes("trend_range") && this.settings.show_trend && !this.settings.trend_range) return false;
      if (fields.includes("row_data") && this.settings.row_data == "segments" && !this.settings.segment) return false;
      if (fields.includes("segment")) {
        if (!this.settings.segment) return false;
        if (!/survey_|responses_count|response_rate|composition|country|language|source|subratings|property_type|sentiment_count/.test(this.settings.segment) && this.settings.segment != "rating" && !this.settings.segmentOption) return false;
      }
      if (fields.includes("benchmark") && this.settings.use_custom_benchmark) {
        if (!this.settings.benchmark_based_on) return false;
        if (this.settings.benchmark_based_on == "companies" && !this.settings.bench_company_ids?.filter(n=> Array.isArray(n) ? n.length : n)?.length) return false;
        if (this.settings.benchmark_based_on == "groups" && !this.settings.bench_group_ids?.filter(n=>Array.isArray(n) ? n.length : n)?.length) return false;
      }
      if (fields.includes("chart_data")) {
        const hasData = this.settings.chart_data.reduce((res, curr) => {
          if (!curr.data_type || !curr.type) return false;
          return res;
        }, true);
        if (this.settings.chart_data.length == 1 && !hasData) return false;
      }
      if (this.settings.order_by && this.orderByOptions.length && !this.settings.order_by_option) return false;
      return true;
    },
    validColumnFields() {
      const fields = this.fields.keys;
      const data   = this.edittedColumn;
      if (fields.includes("columns")) {
        if (!data.type) return false;
        if (/_rating/.test(data.type) && !data.rating_layout) return false;
        if (/_rating/.test(data.type) && data.rating_layout == "highlight" && !data.highlight_from) return false;
        if (data.group_by_period) {
          if (!data.period) return false;
        }
        if ((/composition|country|language|source|travel_type|subratings/.test(data.type) && !data.data_to_show?.length) && !data.col_limit) return false;
        if (data.show_trend && (!data.trend_range || !data.trend_type)) return false;
        if (data.show_benchmark) {
          if (!data.benchmark_based_on) return false;
          if (data.benchmark_based_on == "companies" && !data.bench_company_ids?.filter(n=>n?.length)?.length) return false;
          if (data.benchmark_based_on == "groups" && !data.bench_group_ids?.filter(n=>n?.length)?.length) return false;
        }
      }
      return true;
    },
  }
}
</script>
<template>
  <div class='reports-settings-modal' v-if='companies.length || groups.length'>
    <div class="form-group row" v-if="fields.keys.includes('heading')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.heading") }}</label>
      <span class="col-10">
        <Field rules="required" :name="$t('reports.settings_modal.heading')" v-slot="{ errors }" v-model="settings.heading">
          <multiselect :multiple="false" class='col-10 w-75' v-model="settings.heading" :options="['h1', 'h2', 'h3', 'h4', 'h5', 'h6']" :showLabels=false :placeholder="$t('filter.select_option')" />
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('text')">
      <label class='col-2'>{{ $t("reports.settings_modal.text") }}</label>
      <span class="col-10">
        <Field rules="required" :name="$t('reports.settings_modal.text')" v-slot="{ errors }" v-model="settings.text">
          <input type='text' v-model="settings.text" :placeholder="$t('reports.settings_modal.text_placeholder')" class='form-control' />
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('largetext')">
      <label class='col-2'>{{ $t("reports.settings_modal.largetext") }}</label>
      <span class="col-10">
        <Field rules="required" :name="$t('reports.settings_modal.largetext')" v-slot="{ errors }" v-model="settings.largetext">
          <textarea v-model="settings.largetext" :placeholder="$t('reports.settings_modal.largetext_placeholder')" class='form-control' :style='"min-height: 50px;"' />
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('show_title')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.show_title") }}</label>
      <span class="col-10">
        <input type='checkbox' v-model="settings.show_title" class='form-check-input ms-0'/>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('title') && settings.show_title">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.title") }}</label>
      <span class="col-10 w-75">
        <Field rules="required" :name="$t('reports.settings_modal.title')" v-slot="{ field, errors }" v-model="settings.title">
          <input type='text' v-bind="field" class='form-control'/>
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>

      <label class="col-2 form-label mt-3">{{ $t("reports.settings_modal.title_alignment") }}</label>
      <span class="col-10 w-75 d-flex mt-3">
        <label class="ms-0 custom-label" v-for='a in ["left", "center", "right"]' :key="a">
          <input v-model='settings.title_alignment' class="custom-input" type="radio" name="title-alignment" :id="`title-aligment-${a}`" :value="a" />
          <span class="custom-span">{{ $t(`reports.settings_modal.title_alignment_${a}`) }}</span>
        </label>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('show_questions_without_answers')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.show_questions_without_answers") }}</label>
      <span class="col-10">
        <input type='checkbox' v-model="settings.show_questions_without_answers" class='form-check-input ms-0'/>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('image_url')">
      <div class="form-group row">
        <label class="col-2">{{ $t("reports.settings_modal.image_options.url") }}</label>
        <span class="col-10">
          <Field rules="url|required" :name="$t('reports.settings_modal.image_options.url')" v-slot="{ errors }" v-model="settings.image_url">
            <input class="form-control" type="text" v-model="settings.image_url" :placeholder="$t('reports.settings_modal.image_options.url_placeholder')" />
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </span>
      </div>

      <Field v-model="settings.width" :name="$t('reports.settings_modal.image_options.width')" rules="required|min_value:0|max_value:991" v-slot="{ field, errors }">
        <div class="row form-group mb-3">
          <label class="col-2">{{ $t("reports.settings_modal.image_options.width") }}</label>
          <span style="width: 83.333%;" class="input-group col-9">
            <input type="number" class="form-control" max="991" v-bind="field" @input="$emit('changeInput')"/>
            <span class="input-group-text">px</span>
          </span>
        </div>
        <div class="row mt-2">
          <div class="col-2"></div>
          <span class="col-3 text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </div>
      </Field>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('height')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.image_options.height") }}</label>
      <span class="col-10 w-75">
        <Field :name="$t('reports.settings_modal.image_options.height')" :rules="heightRules" v-slot="{ field, errors }" v-model="settings.height">
          <span class="input-group">
            <input type="number" class="form-control" :min="fields.options?.minHeight" :max="fields.options?.maxHeight" step="5" :placeholder="fields.options?.minHeight || 300" v-bind="field"/>
            <span class="input-group-text">px</span>
          </span>
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('font-size')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.image_options.fontsize") }}</label>
      <span class="col-10 w-75">
        <Field :name="$t('reports.settings_modal.image_options.fontsize')" v-slot="{ field, errors }" v-model="settings.fontsize">
          <span class="input-group">
            <input type="number" class="form-control" step="1" placeholder="14" v-bind="field"/>
            <span class="input-group-text">px</span>
          </span>
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>
    </div>

    <div class="form-group row mb-4" v-if="fields.keys.includes('chart_data') && settings.chart_data">
      <div class="row-data p-2" v-for='k, index in settings.chart_data.length' :key='index'>
        <h6 class='mb-2'>{{ $t('reports.data_n', { n: k }) }}</h6>
        <div class="m-2 row">
          <div class="col-4">
            <label class='mb-1'>{{ $t("reports.settings_modal.data_type") }}</label>
            <span class="">
              <multiselect v-model="settings.chart_data[index].data_type" :options="segmentOptions" :searchable="true"
                :customLabel="s => $t('reports.settings_modal.segment_options.' + s)" />
            </span>
          </div>
          <div class="col-3">
            <label class='mb-1'>{{ $t("reports.settings_modal.drawing_type") }}</label>
            <span class="d-flex">
              <label class="ms-0 custom-label" v-for="ct in chart_types" :key="ct">
                <input v-model='settings.chart_data[index].type' class="custom-input" type="radio" :name="'chart_type-' + k"
                  :id="ct" :value="ct" />
                <span class="custom-span">{{ $t(`reports.settings_modal.chart_types.${ct}`) }}</span>
              </label>
            </span>
          </div>
          <div class="col-4">
            <label class='mb-1'>{{ $t("reports.settings_modal.custom_label_opt") }}</label>
            <input class="form-control" type="text" v-model="settings.chart_data[index].label" :placeholder="$t('reports.settings_modal.segment_options.' + settings.chart_data[index].data_type)" />
          </div>
          <div class="col-1">
            <label class="mb-1 hide">;</label>
            <button class="btn btn-secondary" @click="removeChartData(index)">{{ $t("general.remove") }}</button>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary w-25" @click='addChartData'>{{ $t("reports.add_data") }}</button>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('show_trend')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.show_trend") }}</label>
      <span class="col-10">
        <input type='checkbox' v-model="settings.show_trend" class='form-check-input ms-0' />
      </span>
    </div>

    <div class="form-group row" v-if="settings.show_trend && fields.keys.includes('trend_range')">
      <label class='col-2'>{{ $t("reports.settings_modal.trend_range.label") }}</label>
      <span class="col-10 d-flex">
        <Field rules="required" :name="$t('reports.settings_modal.trend_range.label')" v-slot="{ errors }" v-model="settings.trend_range">
          <label class="ms-0 custom-label" v-for='t in ["yoy", "pop"]' :key='t'>
            <input v-model="settings.trend_range" class="custom-input" type="radio" name="trend_type" :value="t"/>
            <span class="custom-span">{{ $t(`reports.settings_modal.trend_range.${t}`) }}</span>
          </label>
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('row_data')">
      <label class='col-2'>{{ $t("reports.settings_modal.rows") }}</label>
      <span class="col-10 d-flex">
        <Field rules="required" :name="$t('reports.settings_modal.rows')" v-slot="{ errors }" v-model="settings.row_data">
          <label class="ms-0 custom-label" v-for="rt in row_types" :key="rt">
            <input v-model='settings.row_data' class="custom-input" type="radio" name="row_type" :id="rt" :value="rt" />
            <span class="custom-span">{{ $t(`reports.settings_modal.row_types.${rt}`) }}</span>
          </label>
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('use_competitors') && template.based_on == 'companies' && settings.row_data  == 'companies'">
      <label class="col-2">{{ $t("reports.settings_modal.use_competitors") }}</label>
      <span class="col-10">
        <input type="checkbox" v-model="settings.use_competitors" class="form-check-input ms-0"/>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('period')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.period") }}</label>
      <div class="col-10 w-75">
        <Field rules="required" :name="$t('reports.settings_modal.period')" v-slot="{ errors }" v-model="settings.period">
          <multiselect :multiple="false" v-model="settings.period" :options="periodsOptions" :custom-label="p => $t(`reports.periods.${p}`)" :showLabels=false :placeholder="$t('filter.select_option')">
          </multiselect>
          <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
        </Field>
      </div>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('n_periods')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.n_periods") }}</label>
      <span class="col-10">
        <input type='number' v-model="settings.n_periods" :placeholder="6" style="width: 60px;" class='form-control' />
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('show_ranking')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.show_ranking") }}</label>
      <span class="col-10">
        <input type='checkbox' v-model="settings.show_ranking" class='form-check-input ms-0' />
      </span>
    </div>

    <div v-if="fields.keys.includes('segment') || settings.row_data == 'segments'">
      <div class="form-group row">
        <label class="form-label col-2">{{ $t("reports.settings_modal.segment") }}</label>
        <div class="col-10 w-75">
          <Field rules="required" :name="$t('reports.settings_modal.segment')" v-slot="{ errors }" v-model="settings.segment">
            <multiselect :multiple="false" v-model="settings.segment" :options="segmentOptions"
            :searchable="true" :customLabel="s => $t('reports.settings_modal.segment_options.' + s)"
            @select="clearSegmentOptionSelection" @remove="clearSegmentOptionSelection" />
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </div>
      </div>

      <div class="form-group row" v-if="settings.row_data != 'segments' && settings.segment && segmentOptionList(settings.segment).length">
        <label class="col-2">{{ $t("reports.settings_modal.segment_option") }}</label>
        <div class="col-10 w-75">
          <Field rules="required" :name="$t('reports.settings_modal.segment_option')" v-slot="{ errors }" v-model="settings.segmentOption">
            <multiselect :multiple="false" v-model="settings.segmentOption" :options="segmentOptionList(settings.segment)"
            :optionsLimit="segmentOptionList(settings.segment).length" :searchable="true" :customLabel="v => segmentOptionLabel(v, settings.segment)" />
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </div>
      </div>

      <div class="form-group row" v-if="settings.row_data == 'segments' && this.settings.segment">
        <label class="col-2">{{ $t("reports.settings_modal.exclude_ratings") }}</label>
        <div class="col-10 w-75">
          <multiselect :multiple="true" v-model="settings.exclude_ratings" :options="scores" :searchable="true" :customLabel="r => $t(`topics.${r}`)" />
        </div>
      </div>

      <div class="form-group row" v-if='fields.keys.includes("use_low_rated") && /responses_count|response_rate|respondable_responses/.test(settings.segment)'>
        <label class="col-2 form-label">{{ $t("reports.settings_modal.use_low_rated") }}</label>
        <span class="col-10">
          <input type='checkbox' v-model="settings.use_low_rated" class='form-check-input ms-0' />
        </span>
      </div>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('select_surveys')">
      <label class="form-label col-2">{{ $t("reports.settings_modal.surveys") }}</label>
      <div class="col-10 w-75">
        <multiselect :multiple="true" v-model="settings.surveys" :options="surveysOptions" :searchable="true" :customLabel="id => surveys[id] && surveys[id].name" />
      </div>
    </div>

    <div v-if="fields.keys.includes('benchmark') || /survey/.test(settings.segment)">
      <h5 class="mb-3">{{ $t("reports.settings_modal.benchmark.title") }}</h5>
      <settings-modal-benchmark :fields='fields' :settings='settings' :contract='contract' :nBenchmarks='numberOfGroups' :showInline='() => true' />
    </div>

    <template v-if="fields.keys.includes('columns')">
      <!-- show ranking -->
      <div class="form-group row">
        <label class="col-2 form-label">{{ $t("reports.settings_modal.show_ranking") }}</label>
        <span class="col-10">
          <input type='checkbox' v-model="settings.show_ranking" class='form-check-input ms-0' />
        </span>
      </div>

      <div class="justify-content-between align-items-center d-flex" v-if="settings.row_data">
        <h5 class='mb-0'>{{ $t('reports.settings_modal.table_columns') }}</h5>
        <button class="btn btn-primary" @click='addColumn'>{{ $t("reports.settings_modal.add_column") }}</button>
      </div>

      <table class="table table-responsive mt-3 table-bordered generic-table-columns" v-if='fields.keys.includes("columns") && settings.row_data'>
        <tr class='bg-gray-100'>
          <th class='ps-2'>{{ $t('reports.settings_modal.row') }}</th>
          <template v-for='column in settings.columns' :key='column'>
            <th class='px-2 py-3'>{{ $t("reports.settings_modal.segment_options."+column.type) }}</th>
            <th class='ps-2 dashed' v-if='column.show_trend'>{{ $t('reports.settings_modal.trend') }}</th>
            <th class='ps-2 dashed' v-if='column.show_benchmark'>{{ $t('reports.settings_modal.benchmark_column') }}</th>
          </template>
        </tr>

        <tr>
          <td class="p-2">{{ settings.row_data }}</td>
          <template v-for="(column, colIndex) in settings.columns" :key="column">
            <td>
              <div class="extras">
                <div v-if="column.type">
                  <label>{{ $t("reports.settings_modal.segment") }}</label>&nbsp;
                  {{ $t(`reports.settings_modal.segment_options.${column.type}`) }}
                </div>

                <div v-if="column.segmentOption && segmentOptionList(column.type).length">
                  <label>{{ $t("reports.settings_modal.segment_option") }}</label>&nbsp;
                  {{ segmentOptionLabel(column.segmentOption, column.type) }}
                </div>

                <div v-if="/_rating/.test(column.type)">
                  <label>{{ $t("reports.settings_modal.rating_layout") }}</label>&nbsp;
                  {{ $t(`reports.settings_modal.rating_layouts.${column.rating_layout}`) }}
                </div>

                <template v-if="!/responses_count|response_rate|respondable_responses|composition|country|language|source|travel_type|subratings/.test(column.type)">
                  <div>
                    <label>{{ $t("reports.settings_modal.group_by_period") }}</label>&nbsp;
                    {{ $t(`filter.${column.group_by_period ? 'yes' : 'no'}`) }}
                  </div>

                  <template v-if="column.group_by_period">
                    <div>
                      <label>{{ $t("reports.settings_modal.period") }}</label>&nbsp;
                      {{ $t(`reports.periods.${column.period}`) }}
                    </div>

                    <div>
                      <label>{{ $t("reports.settings_modal.period_qtt") }}</label>&nbsp;
                      {{ column.period_qtt || 5 }}
                    </div>
                  </template>
                </template>

                <template v-if="/composition|country|language|source|travel_type|subratings/.test(column.type)">
                  <div v-if="column.data_to_show && column.data_to_show.length">
                    <label>{{ $t("reports.settings_modal.data_to_show") }}</label>&nbsp;
                    {{ column.data_to_show.map(d => this.$t(`reports.settings_modal.segment_options.${d}`)).join(", ") }}
                  </div>

                  <div v-if="column.coloured_columns && column.coloured_columns.length">
                    <label>{{ $t('reports.settings_modal.coloured_columns') }}</label>&nbsp;
                    {{ column.coloured_columns.map(d => this.$t(`reports.settings_modal.segment_options.${d}`)).join(", ") }}
                  </div>

                  <div v-if="column.col_limit">
                    <label>{{ $t('reports.settings_modal.col_limit') }}</label>&nbsp;
                    {{ column.col_limit }}
                  </div>
                </template>

                <div v-if="column.label">
                  <label>{{ $t("reports.settings_modal.custom_label") }}</label>&nbsp;
                  {{ column.label }}
                </div>
              </div>

              <div>
                <a class="btn btn-outline-primary me-2" @click="editColumn(column, colIndex)" :title="$t('reports.settings_modal.edit_column')"><i class='fa fa-pen'></i></a>
                <a class="btn btn-outline-primary" @click="removeColumn(column, colIndex)" :title="$t('reports.settings_modal.remove_column')"><i class='fa fa-trash'></i></a>
              </div>
            </td>

            <td class="dashed" v-if="column.show_trend">
              <div class="extras">
                <div>
                  <label v-if="column.trend_range">{{ $t("reports.settings_modal.trend_range.label") }}</label>&nbsp;
                  {{ $t(`reports.settings_modal.trend_range.${column.trend_range}`) }}
                </div>

                <div>
                  <label v-if="column.trend_type">{{ $t("reports.settings_modal.trend_type") }}</label>&nbsp;
                  {{ $t(`reports.settings_modal.trend_types.${column.trend_type}`) }}
                </div>
              </div>
            </td>

            <td class="dashed" v-if="column.show_benchmark">
              <div class="extras">
                <div>
                  <label>{{ $t("reports.settings_modal.benchmark_with") }}</label>&nbsp;
                  {{ $t(`reports.settings_modal.segment_options.${column.type}`) }}
                </div>

                <div>
                  <label>{{ $t("reports.settings_modal.benchmark_using") }}</label>&nbsp;
                  {{ benchNames(column) }}
                </div>
              </div>
            </td>
          </template>
        </tr>
      </table>
    </template>

    <template v-if="(fields.keys.includes('columns') || fields.keys.includes('order_by')) && settings.columns.length">
      <div class="form-group row">
        <label class="form-label col-2">{{ $t("reports.settings_modal.order_by") }}</label>
        <span class="col-10 w-75">
          <multiselect :multiple="false" v-model="settings.order_by" :options="columnTypes" :searchable="true" :customLabel="orderByLabels" />
        </span>
      </div>

      <div class="form-group row" v-if="orderByOptions.length">
        <label class="form-label col-2">{{ $t("reports.settings_modal.order_by_option") }}</label>
        <span class="col-10 w-75">
          <Field rules="required" :name="$t('reports.settings_modal.order_by_option')" v-slot="{ errors }" v-model="settings.order_by_option">
            <multiselect :multiple="false" v-model="settings.order_by_option" :options="orderByOptions" :searchable="true" :customLabel="orderByLabels" />
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </span>
      </div>

      <div class="form-group row">
        <label class="form-label col-2">{{ $t("reports.settings_modal.order") }}</label>
        <span class="col-10 d-flex">
          <label class="ms-0 custom-label" v-for="o in orderOptions" :key="o">
            <input v-model='settings.order' class="custom-input" type="radio" name="order" :value="o" :checked="(settings.order == o)"/>
            <span class="custom-span">{{ $t(`reports.settings_modal.order_options.${o}`) }}</span>
          </label>
        </span>
      </div>
    </template>

    <div class="form-group row" v-if="fields.keys.includes('min_reviews')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.min_reviews") }}</label>
      <span class="col-10">
        <input type='number' v-model="settings.min_reviews" :placeholder="15" style="width: 60px;" class='form-control' />
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('random')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.random") }}</label>
      <span class="col-10">
        <input type="checkbox" v-model="settings.random" class="form-check-input ms-0"/>
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('limit')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.limit") }}</label>
      <span class="col-10">
        <input type='number' v-model="settings.limit" :placeholder="5" style="width: 60px;" class='form-control' />
      </span>
    </div>

    <div class="form-group row" v-if="fields.keys.includes('select_columns')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.select_columns") }}</label>
      <div class="col-10">
        <div class='form-group' v-for='k in fields.options.columns' :key='k'>
          <input :id='"select-columns-"+k' type='checkbox' v-model="settings.shownColumns[k]" class='ms-0 me-1' />
          <label :for='"select-columns-"+k' class='form-label'>{{ $t('reports.settings_modal.segment_options.'+ k) }}</label>
        </div>
      </div>
    </div>

    <template v-if="fields.keys.includes('filters')">
      <div class="form-group row" v-if="fields.options.filterCols.includes('property_type')">
        <label class="form-label col-2">{{ $t("reports.settings_modal.property_type") }}</label>
        <span class="col-10 w-75">
          <multiselect v-model="settings.filters.property_type" :options="propertyTypes" :searchable="true" :customLabel="s => $t('property_types.'+ s)" />
        </span>
      </div>

      <div class="form-group row" v-if="fields.options.filterCols.includes('source')">
        <label class="form-label col-2">{{ $t("reports.settings_modal.source") }}</label>
        <span class="col-10 w-75">
          <multiselect :multiple='true' v-model="settings.filters.source" :options="contractSources" :searchable="true" :customLabel="sourceLabel" />
        </span>
      </div>
    </template>

    <div class="form-group row" v-if="fields.keys.includes('custom_labels')">
      <label class="col-2 form-label">{{ $t("reports.settings_modal.custom_label_opt") }}</label>
      <div class="col-10">
        <span class='form-group row' v-for='type in fields.options.labels' :key='type'>
          <span class="col-2 custom-span">{{ $t('reports.settings_modal.segment_options.'+ type) }}</span>
          <input type='text' v-model="settings.labels[type]" :placeholder="$t('reports.settings_modal.segment_options.'+ type)" style="width: 200px;" class='form-control col-10' />
        </span>
      </div>
    </div>

    <b-modal v-if="edittedColumn" centered hide-footer v-model="showAddColumn" size="lg" :ok-title="$t('reports.settings_modal.save')" :title="$t('reports.settings_modal.add_column')" @hidden="cancelAddColumn">
      <div>
        <div class="form-group">
          <label class="form-label">{{ $t("reports.settings_modal.segment") }}</label>
          <Field rules="required" :name="$t('reports.settings_modal.segment')" v-slot="{ errors }" v-model="edittedColumn.type">
            <multiselect :multiple="false" v-model="edittedColumn.type" :options="columnSegmentOptions" :searchable="true" :customLabel="s => $t('reports.settings_modal.segment_options.'+ s)" />
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </div>

        <div class="form-group" v-if="edittedColumn.type && segmentOptionList(edittedColumn.type).length">
          <label class="form-label">{{ $t("reports.settings_modal.segment_option") }}</label>
          <Field rules="required" :name="$t('reports.settings_modal.segment_option')" v-slot="{ errors }" v-model="edittedColumn.segmentOption">
            <multiselect :multiple="false" v-model="edittedColumn.segmentOption" :options="segmentOptionList(edittedColumn.type)"
            :optionsLimit="segmentOptionList(edittedColumn.type).length" :searchable="true" :customLabel="v => segmentOptionLabel(v, edittedColumn.type)" />
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </div>

        <template v-if="/composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <div class="form-group row align-items-center">
            <label class="col-4">{{ $t("reports.settings_modal.data_to_show") }}</label>
            <div class="col-8">
              <Field rules="required" :name="$t('reports.settings_modal.data_to_show')" v-slot="{ errors }" v-model="edittedColumn.data_to_show">
                <multiselect :multiple="true" v-model="edittedColumn.data_to_show" :options="dataToShowOptions" :searchable="true" :customLabel="p => $t(`reports.settings_modal.segment_options.${p}`)" />
                <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
              </Field>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label class="col-4">{{ $t("reports.settings_modal.coloured_columns") }}</label>
            <div class="col-8">
              <multiselect :multiple="true" v-model="edittedColumn.coloured_columns" :options="highlightList" :searchable="true" :customLabel="p => $t(`reports.settings_modal.segment_options.${p}`)" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-4">{{ $t("reports.settings_modal.col_limit") }}</label>
            <div class="col-8">
              <input type="number" v-model="edittedColumn.col_limit" :placeholder="5" style="width: 60px;" class="form-control" />
            </div>
          </div>
        </template>

        <div class="form-group row" v-if='/_rating/.test(edittedColumn.type)'>
          <label class='form-label col-4'>{{ $t("reports.settings_modal.rating_layout") }}</label>
          <span class="col-8 d-flex">
            <Field rules="required" :name="$t('reports.settings_modal.rating_layout')" v-slot="{ errors }" v-model="edittedColumn.rating_layout">
              <label class="ms-0 custom-label" v-for="rl in ['only_number', 'bars', 'coloured_background', 'highlight']" :key="rl">
                <input v-model='edittedColumn.rating_layout' class="custom-input" type="radio" name="rating_background" :id="rl" :value="rl" :checked="(edittedColumn.rating_layout == rl)"/>
                <span class="custom-span">{{ $t(`reports.settings_modal.rating_layouts.${rl}`) }}</span>
              </label>
              <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
            </Field>
          </span>

          <div class="row my-3" v-if="edittedColumn.rating_layout == 'highlight'">
            <div class="col-4 d-flex flex-column align-items-start">
              <label class="form-label">{{ $t("reports.settings_modal.highlight_ratings_from") }}</label>
              <input type="number" v-model="edittedColumn.highlight_from" min="0" max="10" placeholder="8" style="width: 60px;" class="form-control" />
            </div>

            <div class="col-4 d-flex flex-column align-items-start">
              <label class="form-label">{{ $t("reports.settings_modal.highlight_ratings_color") }}</label>
              <ColorPicker v-model:pureColor="edittedColumn.highlight_color" useType="pure" format="hex" pickerType="chrome" lang="En" />
            </div>

            <div class="col-4 d-flex flex-column align-items-start">
              <label class="form-label">{{ $t("reports.settings_modal.highlight_font_color") }}</label>
              <ColorPicker v-model:pureColor="edittedColumn.highlight_font_color" useType="pure" format="hex" pickerType="chrome" lang="En" />
            </div>
          </div>
        </div>

        <template v-if="!/responses_count|response_rate|respondable_responses|composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <div class="form-group row mt-4">
            <label class="col-4 form-label">{{ $t("reports.settings_modal.group_by_period") }}</label>
            <span class="col-8">
              <input type="checkbox" v-model="edittedColumn.group_by_period" class="form-check-input ms-0"/>
            </span>
          </div>

          <template v-if="edittedColumn.group_by_period">
            <div class="form-group row">
              <label class="col-4">{{ $t("reports.settings_modal.period") }}</label>
              <div class="col-8">
                <Field rules="required" :name="$t('reports.settings_modal.period')" v-slot="{ errors }" v-model="edittedColumn.period">
                  <multiselect :multiple="false" v-model="edittedColumn.period" :options="periodOptions" :searchable="true" :customLabel="p => $t(`reports.periods.${p}`)" />
                  <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
                </Field>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4">{{ $t("reports.settings_modal.period_qtt") }}</label>
              <div class="col-8">
                <input type="number" v-model="edittedColumn.period_qtt" :placeholder="5" style="width: 60px;" class="form-control" />
              </div>
            </div>
          </template>
        </template>

        <div class="form-group row mt-4" v-if="!edittedColumn.group_by_period && !/composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <label class="col-4 form-label">{{ $t("reports.settings_modal.show_trend") }}</label>
          <span class="col-8">
            <input type='checkbox' v-model="edittedColumn.show_trend" class='form-check-input ms-0'/>
          </span>
        </div>

        <div class="form-group row" v-if="edittedColumn.show_trend && !edittedColumn.group_by_period && !/composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <label class='col-4'>{{ $t("reports.settings_modal.trend_range.label") }}</label>
          <span class="col-8 d-flex">
            <label class="ms-0 custom-label" v-for='t in ["yoy", "pop"]' :key='t'>
              <input v-model='edittedColumn.trend_range' class="custom-input" type="radio" name="trend_range" :value="t"/>
              <span class="custom-span">{{ $t(`reports.settings_modal.trend_range.${t}`) }}</span>
            </label>
          </span>
        </div>

        <div class="form-group row" v-if="edittedColumn.show_trend && !edittedColumn.group_by_period && !/composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <label class='col-4'>{{ $t("reports.settings_modal.trend_type") }}</label>
          <span class="col-8 d-flex">
            <label class="ms-0 custom-label" v-for='t in ["percentage", "value", "both"]' :key='t'>
              <input v-model='edittedColumn.trend_type' class="custom-input" type="radio" name="trend_type" :value="t"/>
              <span class="custom-span">{{ $t(`reports.settings_modal.trend_types.${t}`) }}</span>
            </label>
          </span>
        </div>

        <div class="form-group row mt-4" v-if="!edittedColumn.group_by_period && !/composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <label class="col-4 form-label">{{ $t("reports.settings_modal.show_benchmark") }}</label>
          <span class="col-8">
            <input type='checkbox' v-model="edittedColumn.show_benchmark" class='form-check-input ms-0'/>
          </span>
        </div>

        <div class="form-group row" v-if="edittedColumn.show_benchmark && !edittedColumn.group_by_period && !/composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <h5 class="mb-3">{{ $t("reports.settings_modal.benchmark.title") }}</h5>
          <settings-modal-benchmark :fields='fields' :settings='edittedColumn' :contract='contract' :nBenchmarks='nBenchmarks' :showInline='() => false' />
        </div>

        <div class="form-group">
          <label class="form-label">{{ $t("reports.settings_modal.custom_label_opt") }}</label>
          <input class="form-control" type="text" v-model="edittedColumn.label" :placeholder="customLabelPlaceholder" />
        </div>

        <div class="form-group" v-if="edittedColumn.show_trend && !edittedColumn.group_by_period && !/composition|country|language|source|travel_type|subratings/.test(edittedColumn.type)">
          <label class="form-label">{{ $t("reports.settings_modal.custom_trend_label_opt") }}</label>
          <input class="form-control" type="text" v-model="edittedColumn.trend_label" :placeholder="$t('reports.settings_modal.trend_label')" />
        </div>

        <div class="d-flex flex-wrap align-items-center justify-content-end">
          <button class="btn btn-secondary btn-md btn" @click="cancelAddColumn">{{ $t("reports.settings_modal.cancel") }}</button>
          <button class="btn btn-primary btn-md btn ms-2" :disabled="!validColumnFields" @click="saveColumn">{{ $t("reports.settings_modal.save") }}</button>
        </div>
      </div>
    </b-modal>

    <div v-if="fields.keys.includes('html')" class="mb-3">
      <SettingsModalHtml :settings="settings" :template='template' @input="updateHtmlValue" />
    </div>

    <div class="d-flex flex-wrap align-items-center justify-content-end">
      <button class="btn btn-secondary btn-md btn" @click="close">{{ $t("reports.settings_modal.cancel") }}</button>
      <button class="btn btn-primary btn-md btn ms-2" :disabled="!validFields" @click="save">{{ $t("reports.settings_modal.save") }}</button>
    </div>
  </div>
</template>
