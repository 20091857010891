<script>
import axios from "axios";

export default {
  data() {
    return {
      scriptLink: "https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js",
      script:  null,
      distURL: null,
      runObserver:  null,
      lightGreen: "#55c5cc",
      darkGreen: "#47AAAA",
      images: {},
      searchInputRef: null,
      typingDelay: 200,
      nextUpdate: null,
      searchString: "",
      observer: null
    };
  },
  async created() {
    this.importImages();
    await this.getDist();
    this.loadScript();
    this.runObserver = setInterval(() => {
      this.menuObserver();
      this.searchInputRef = document.querySelector(".search-input");
      if (this.searchInputRef) this.searchInputRef.addEventListener("input", this.inputEvent);
    }, 1500);
  },
  mounted() {
    let redocContainerRef = this.$refs.redoc_container;
    if (Array.isArray(redocContainerRef)) redocContainerRef = redocContainerRef[0];

    this.observer = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.addedNodes.length != 1) return;
        let addedDiv = mutation.addedNodes[0];
        if (addedDiv.classList.contains("redoc-wrap")) this.scrollIntoView();
      });
    });

    this.observer.observe(redocContainerRef, { childList: true });
  },
  beforeUnmount() {
    this.observer.disconnect();
    this.items.forEach(node => node.removeEventListener("click", this.clickEvent));
    this.searchInputRef?.removeEventListener("input", this.inputEvent);
    this.removeScript();
  },
  methods: {
    scrollIntoView() {
      setTimeout(() => {
        const hash = this.$route.hash?.substring(1);
        if (hash) this.scrollIntoScreen(hash);
      }, 100);
    },
    importImages() {
      const r = require.context("@/assets/images/documentation", false, /\.(png|jpe?g|svg)$/)
      r.keys().map(item => this.images[item.replace("@/", "")] = r(item));
    },
    async getDist() {
      const response = await axios.get("/v3/docs/dist.json_url");
      this.distURL = response.data.data.dist_url;
    },
    loadScript() {
      if (document.querySelector(`script[src="${this.scriptLink}"]`)) return;

      this.script = document.createElement("script");
      this.script.src   = this.scriptLink;
      this.script.async = false;
      document.head.appendChild(this.script);
    },
    removeScript() {
      if (this.script) this.script.parentNode.removeChild(this.script);
      this.script = null;
    },
    menuObserver() {
      if (!this.items.length) return;
      clearInterval(this.runObserver);
      this.connectEvent(true);
    },
    clickEvent(event) {
      this.scrollIntoScreen(event.currentTarget.getAttribute("data-item-id"));
      event.stopPropagation();
    },
    inputEvent(event) {
      this.nextUpdate = Date.now() + this.typingDelay;
      setTimeout(() => {
        if (this.nextUpdate > Date.now()) return;
        this.searchString = event.target.value;
      }, this.typingDelay);
    },
    scrollIntoScreen(id) {
      const element         = document.getElementById(id);
      const headerOffset    = -40;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition  = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top:      offsetPosition,
        behavior: "smooth",
        block:    "start",
        inline:   "nearest",
      });
    },
    connectEvent(value) {
      if (!value) return;
      if (this.items.length) this.items.forEach(node => node.addEventListener("click", this.clickEvent));
      else this.runObserver = setInterval(this.menuObserver, 1000);
    },
    backToDashboard() {
      this.$router.push({ path: "/", query: { ...this.$route.query } });
    },
  },
  computed: {
    items() {
      return (this.searchString || true) && document.querySelectorAll("[data-item-id]");
    },
    theme() {
      return {
        logo:      { gutter: "20px" },
        codeBlock: { backgroundColor: "#11171a" },
        sidebar:   { activeTextColor: this.darkGreen },
        typography: {
          code:  { color: this.darkGreen },
          links: { color: this.darkGreen }
        },
        rightPanel: {
          backgroundColor: this.darkGreen,
          width: "33%"
        }
      }
    }
  },
  watch: {
    searchString() { this.connectEvent(true) }
  },
}
</script>

<template>
  <div class="position-relative">
    <redoc ref="redoc_container" class="redoc-container" :spec-url="distURL" :theme="JSON.stringify(theme)"></redoc>
    <button class="btn btn-docs-back" @click="backToDashboard">{{ $t("general.back_to_dashboard") }}</button>
  </div>
</template>
