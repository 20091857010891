<script>
import PageHeader from "@/components/page-header";
import Utils from "@/js/utils";
import DataUtils from "@/js/data-utils";
import Swal  from "sweetalert2";
import { Field } from "vee-validate";
import { axios } from "@/js/reports";

export default {
  data() {
    return {
      loading: false,
      passwordVisibility: { password: false, confirm_password: false },
      originalData: {},
      data: {
        first_name: null,
        surname_prefix: null,
        surname: null,
        email: null,
        password: null,
        confirm_password: null
      },
      fields: ["first_name", "surname_prefix", "surname", "email", "password"],
      acceptTerms: false,
      login_url: "https://dashboard.olery.com/users/sign_in",
      support_email: "support@olery.com",
      token: null
    };
  },
  components: { PageHeader, Field },
  async created() {
    this.token     = this.$route.query.token;
    const response = await axios.get("/v3/pre_signup/", { params: { token: this.token } });

    if (!response.data.data) this.alertURL("url_invalid");
    this.data = { ...this.data, ...response.data.data };

    if (this.data.completed_at) this.alertURL("url_already_used");
    this.originalData = Utils.deepClone(this.data);
  },
  mounted() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    ...DataUtils,
    async signUp() {
      this.loading = true;
      const params = this.fields.reduce((res, curr) => ({ ...res, [curr]: this.data[curr] || undefined }), {});
      const response = await axios.post(`/v3/pre_signup/?token=${this.token}`, { user: params });
      this.originalData = { ...response.data.data.user, password: this.data.password };
      this.token = response.data.data.user.authentication_token;
      this.loading = false;
      window.location.href = `/documentation/api?auth_token=${this.token}`;
    },
    loadDiff() {
      let diff = this.fields.reduce((res, field) => {
        if ((this.data[field] || "") != (this.originalData[field] || "")) res[field] = this.data[field];
        return res;
      }, {});
      return diff;
    },
    validateFields() {
      if (this.data.password != this.data.confirm_password) return this.alert("passwords_not_match");
      if (!(this.data.email && this.data.email.length)) return this.alert("email_empty");
      if (!Utils.validEmail(this.data.email)) return this.alert("email_invalid");
      this.signUp();
    },
    beforeWindowUnload(event) {
      if (!this.data.completed_at && this.isDirty && this.confirmStayInDirtyForm()) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    confirmStayInDirtyForm() {
      return !window.confirm(this.$t("new_survey.confirm_leave"));
    },
    alert(message) {
      Swal.fire(this.$t("general.error"), this.$t(`user_management.user_settings.${message}`), "error");
      this.loading = false;
    },
    alertURL(message) {
      Swal.fire({
        title: this.$t("customer_filters.warning"),
        icon: "warning",
        showCancelButton:  false,
        showConfirmButton: false,
        allowOutsideClick: false,
        footer: "<br>",
        html: this.$t(`signup.${message}`, {
          login_url:     `<a class='text-primary' href='${this.login_url}' target='_blank'>${this.$t('general.login_page')}</a>`,
          support_email: `<a class='text-primary' href='mailto:${this.support_email}'>${this.support_email}</a>`
        })
      });
    },
    togglePasswordVisibility(field) {
      this.passwordVisibility[field] = !this.passwordVisibility[field];
    }
  },
  computed: {
    isDirty() {
      let diff = this.loadDiff();
      return Object.keys(diff).length;
    },
    passwordType() {
      return Object.entries(this.passwordVisibility).reduce((res, [key, value]) => {
        res[key] = value ? "text" : "password";
        return res;
      }, {});
    },
    eyeIcon() {
      return Object.entries(this.passwordVisibility).reduce((res, [key, value]) => {
        res[key] = value ? "mdi-eye-off-outline" : "mdi-eye-outline";
        return res;
      }, {});
    },
    rules() {
      return {
        password:         "required|min:6|upper_case:1",
        confirm_password: `required|same:${this.data.password}`
      };
    }
  }
}
</script>

<template>
  <div class="px-3 px-sm-5 mx-md-5">
    <PageHeader :title="$t('signup.title')" />

    <div class="card" :class="{ 'loading': loading }">
      <div class="card-body mt-2">
        <!-- Name -->
        <Field rules="required" :name="$t('user_management.user_settings.first_name')" v-slot="{ field, errors }" v-model="data.first_name">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("user_management.user_settings.first_name") }}</label>
            <div class="position-relative">
              <input type="text" class="form-control" :placeholder="$t('user_management.user_settings.name_help')" v-model="data.first_name" autocomplete="given-name" v-bind="field" />
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>

        <!-- Prefix Surname -->
        <div class="form-group px-sm-5 mx-sm-5">
          <label class="form-label">{{ $t("user_management.user_settings.prefix_surname") }}</label>
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('user_management.user_settings.prefix_surname_help')" v-model="data.surname_prefix" autocomplete="additional-name" />
          </div>
        </div>

        <!-- Surname -->
        <div class="form-group px-sm-5 mx-sm-5">
          <label class="form-label">{{ $t("user_management.user_settings.surname") }}</label>
          <div class="position-relative">
            <input type="text" class="form-control" :placeholder="$t('user_management.user_settings.surname_help')" v-model="data.surname" autocomplete="family-name" />
          </div>
        </div>

        <!-- E-mail -->
        <Field rules="email" :name="$t('user_management.user_settings.email')" v-slot="{ field, errors }" v-model="data.email">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("user_management.user_settings.email") }}</label>
            <div class="position-relative">
              <input type="email" class="form-control" :placeholder="$t('user_management.users.add_email_placeholder')" autocomplete="email" name="email" v-bind="field" />
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>

        <!-- Create password -->
        <Field :rules="rules.password" :name="$t('signup.create_password')" v-slot="{ field, errors }">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("signup.create_password") }}</label>
            <div class="d-flex align-items-center">
              <input :type="passwordType.password" class="form-control" :placeholder="$t('signup.create_password_help')" v-model="data.password" v-bind="field" autocomplete="new-password" />
              <i class="mdi font-size-22 cursor-pointer ms-2" :class="eyeIcon.password" @click="togglePasswordVisibility('password')" v-b-tooltip.hover :title="$t('user_management.user_settings.toggle_password_visibility')"></i>
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>

        <!-- Confirm password -->
        <Field :rules="rules.confirm_password" :name="$t('signup.confirm_password')" v-slot="{ field, errors }">
          <div class="form-group px-sm-5 mx-sm-5">
            <label class="form-label">{{ $t("signup.confirm_password") }}</label>
            <div class="d-flex align-items-center">
              <input :type="passwordType.confirm_password" class="form-control" :placeholder="$t('signup.confirm_password_help')" v-model="data.confirm_password" v-bind="field" autocomplete="new-password" />
              <i class="mdi font-size-22 cursor-pointer ms-2" :class="eyeIcon.confirm_password" @click="togglePasswordVisibility('confirm_password')" v-b-tooltip.hover :title="$t('user_management.user_settings.toggle_password_visibility')"></i>
            </div>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </div>
        </Field>

        <!-- NDA -->
        <div class="form-group px-sm-5 mx-sm-5 mt-4" v-if="data.nda_url">
          <label class="form-label">{{ $t("signup.terms_of_service") }}</label>
          <object class="mb-3 border border-primary-subtle rounded" :data="`${data.nda_url}#toolbar=1&navpanes=0&scrollbar=0`" type="application/pdf" width="100%" height="600px">
            <i18n-t scope="global" keypath="general.unable_to_show_pdf">
              <template v-slot:link>
                <a class="text-primary" :href="data.nda_url" target="_blank">{{ $t("general.download") }}</a>
              </template>
            </i18n-t>
          </object>

          <b-form-checkbox v-model="acceptTerms" size="lg">
            <span class="font-size-14">{{ $t("signup.accept_terms") }}</span>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <button class="btn btn-primary mb-4 px-5 py-2 float-end" :disabled="!acceptTerms" @click="validateFields"> {{ $t("new_survey.settings.save") }} </button>
  </div>
</template>
