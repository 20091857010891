<script>
import Reports   from "@/js/reports";
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";
import Utils     from "@/js/utils";

export default {
  data() {
    return {
      loading: true,
      error: false,
      groupNames: [],
      chartData: [],
      chartOptions: {
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          labels: {
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
            formatter: function (value) {
              return value + "%"
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: ["#000"],
              fontSize: '14px',
              fontWeight: 500,
            },
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#000",
            width: 6,
            offsetX: 0,
            offsetY: 0
          },
        },
        fill: {
          opacity: 1
        },
        chart: {
          type: "bar",
          stacked: true,
          stackType: "100%",
          toolbar: { show: false }
        },
        colors: ['#3CB371', '#FFA500', '#FF6347'], // Custom colors for good, average, and bad
        tooltip: { y: { formatter: val => Utils.round(val, 2) + "%" } },
        dataLabels: {
          enabled: true,
          formatter: val => Utils.round(val, 2) + "%"
        },
      },
    };
  },
  props: ['settings', 'start_date', 'end_date', 'group_id', 'group_ids', 'company_id'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.error = false;
        this.loading = true;
        const identifier = this.settings.segment == 'response_rate' ? 'group_responses_good_avg_bad' : 'groups_by_ratings'
        if (!this.settings.use_custom_benchmark)
          this.settings.bench_group_ids = [ ...new Set(this.group_ids)]

        // remove group_id as it is already being added
        const i = this.settings.bench_group_ids.indexOf(this.group_id.toString())
        if (i >= 0) this.settings.bench_group_ids.splice(i, 1)

        let response     = await this.getReports(identifier)

        // Process the response data and calculate the total ratings for each group
        const data = response.slice(1);

        const groupNames = data.map(item => item[1]);
        const ratings = data.map(item => item.slice(2));

        // Calculate total ratings for each group
        const totalRatings = ratings.map(ratingRow =>
          ratingRow.reduce((total, rating) => total + rating, 0)
        );

        // Calculate percentages based on total ratings for each rating type
        const percentageRatings = ratings.map((ratingRow, index) =>
          ratingRow.map(rating => (rating / totalRatings[index]) * 100)
        );

        // Transpose the percentage data for each rating type
        const transposedPercentages = percentageRatings[0].map((col, i) =>
          percentageRatings.map(row => row[i])
        );

        const ratingTypes = ['good', 'average', 'bad']
        // Create series data with different colors for each rating type
        const seriesData = transposedPercentages.map((values, index) => ({
          name: ratingTypes[index],
          data: values,
        }));


        this.chartData = seriesData;
        this.groupNames = groupNames;

        // // Set Y-axis categories to group names
        this.chartOptions.xaxis.categories = this.groupNames;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.error = true;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<template>
  <div>
    <apexchart v-if="chartData && chartData.length" class="mt-5" :height="(groupNames.length * 70) + 40" dir="ltr" :series="chartData" :options="chartOptions" ref="column-chart"></apexchart>

    <NotLoaded :data="chartData" :error="error" v-else-if="!loading" />
    <Loading v-else-if="loading" />
  </div>
</template>
