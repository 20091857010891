<script>
import axios from "axios";
import Utils from "@/js/utils";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);
import OleryTable from "@/components/olery-table";

export default{
  data () {
    return {
      loading: false,
      sortBy: "count",
      sortDesc: true,
      previousFilterParams: {},
      company_ids: [],
      rows: [],
      baseCols: [
        { field: "property",        text: this.$t("source_data.property"), sortable: true },
        { field: "source_friendly", text: this.$t("source_data.source"),   sortable: true }
      ],
      cols: []
      
    }
  },
  mounted () {
    this.loadListener();
  },
  watch: {
    settings: function () {
      this.loadListener();
    }
  },
  props: {
    settings: Object, start_date: String, end_date: String,
    company_id: [Number, String, Object], group_id: [Number, String, Object], showExport: Boolean
  },
  components: {OleryTable},
  methods: {
    async loadListener() {
      let params = { start_date: this.start_date, end_date: this.end_date };
      params.per = this.settings.period || "month";
      if (typeof this.company_id == 'object') this.company_ids = this.company_id
      else this.company_ids = Array(this.company_id).filter(n => n)
      if (typeof this.group_id == 'object') this.group_ids = this.group_id
      else this.group_ids = Array(this.group_id).filter(n => n)

      if (this.group_id) {
        await this.$store.dispatch("groups/fetch", { contract_id: this.contract.id }).then(groups => {
          this.groups = groups;
          let ids = groups.filter(g => this.group_ids.includes(g.id.toFixed())).flatMap(g => g.companies.map(c => c.id))
          this.company_ids = this.company_ids.concat(ids)
        })
      }

      if (this.company_ids.length) this.load(params);
    },
    async load(params) {
      Utils.setLoading.bind(this)(true);
      this.cols    = [...this.baseCols];
      this.rows    = [];
      let promises = [];

      this.company_ids.forEach(cId => {
        promises.push(axios.get(`/v3/companies/${cId}/source_ratings`, { params }).then(response => {
          this.createRow(response.data.data, params.per);
          Utils.setLoading.bind(this)(false);
        }));
      });

      await Promise.all(promises);
      this.cols.sort((a, b) => dayjs(b.field).diff(dayjs(a.field)));
    },
    createRow(data, period) {
      data.data.forEach(source => {
        let row = { property: data.meta.company.name, source: source.name, source_friendly: source.friendly_name, scale: 10 / (source.scale_max || 5.0) };
        source.per_date.forEach(perDate => {
          let label = this.createColLabel(perDate.date, period);
          row[perDate.date] = Number(Utils.round(perDate.rating, 2)).toFixed(2);
          let col = this.cols.find(c => c.field == perDate.date);
          if (!col) this.cols.push({ field: perDate.date, text: label, sortable: true, type: "number", f: 2 });
        });
        this.rows.push(row);
      });
    },
    columns(field, label) {
      return this.cols.reduce((res, curr) => {
        res.push({ ...curr, [field]: curr.field, [label]: curr.text });
        return res;
      }, []);
    },
    createColLabel(date, period) {
      if (/day|week|quarter/.test(period)) return dayjs(date).format("MMM DD");
      if (period == "month") return dayjs(date).format("MMM YYYY");
      if (period == "year") return dayjs(date).format("YYYY");
    },
    loadParamsChange() {
      if (!Object.keys(this.previousFilterParams).length) return [];
      return ["start_date", "end_date", "filters", "period", "company_id"].reduce((res, currValue, currIndex) => {
        if (currIndex < 3 && JSON.stringify(this.previousFilterParams[currValue]) != JSON.stringify(this.settings[currValue])) res.push(currValue);
        else if (JSON.stringify(this.previousFilterParams[currValue]) != JSON.stringify(this.settings[currValue])) res.push(currValue);
        return res;
      }, []);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    computedRows() {
      let result = [...this.rows];

      let filterSources = [], exclude = this.settings.filters?.source_exclusion == true;
      if (exclude) filterSources = this.settings?.filters?.exclude_sources || [];
      else filterSources = this.settings?.filters?.source || [];

      if (filterSources.length) result = result.filter(r => {
        if (exclude) return !filterSources.includes(r.source);
        return filterSources.includes(r.source);
      });

      return result;
    },
    fileName() {
      return this.$t("general.export_file_name", {
        title: this.$t("source_data.title"),
        sd: this.start_date,
        ed: this.end_date
      });
    }
  }
}
</script>

<template>
  <div :class="{ 'loading': loading }">
    <OleryTable
      :fileName="fileName"
      :btnText="$t('general.export_excel')" btnClass="btn btn-secondary text-nowrap"
      :ratingCols="cols.slice(2).map(f=>f.field)"
      :hideExport='!showExport'
      :dataTable="{
        tableHeaderClass:    'thead-light',
        items:               computedRows,
        headers:             columns('value', 'text'),
        headerTextDirection: 'center',
        bodyTextDirection:   'center',
        sortBy:              sortBy,
        sortType:            'desc',
        pagination:          false
      }"
    />
  </div>
</template>
