<script>
import dayjs from 'dayjs'
import axios from 'axios'
import { VAceEditor } from 'vue3-ace-editor'
import Multiselect from 'vue-multiselect'

import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';

export default {
  data() {
    return {
      dirtyEditor: false,
      content: 'select * from reviews where hotel_id = <%=company_id%> limit 10',
      backend: 'db',
      editor: {
        options: {
          fontSize: '13pt',
          wrap:     100,
          tabSize:  2,
        },
      },
      paramsEditor: {
        options: {
          fontSize: '13pt',
          wrap:     70,
        },
      },

      queries: [],
      params:  JSON.stringify({
        row: 'company', segment: null,
        subscription_id: [],
        start_date: '2023-01-01', end_date: '2023-03-31',
        periods: {yoy: 1, previous: 1, next: 1},
        company_id: [690976], group_id: [640],
        // bench
        company_ids: [690976], group_ids: [641,642,643,754],
        source: ['trip_advisor'], rk_source: 'trip_advisor',

        with_numerical_ratings: null,
        with_sentiment_ratings: null,
        with_response_rate: null,
        limit: null, sort: null, order: null,
        property_type: null, min_reviews: null,
        compset: 'false', property: 'grouped', period: 'month',

        release_date: dayjs().format('YYYY-MM-DD'),
        date_format: null, last_30d: null,  last_3m: null, last_12m: null,
        prev_12m: null, prev_months: null, fill_12m: null, next_3m: null,
        ytd: null, mtd: null, wtd: null, months_ago: null,
      }, null, 2),
      query:    null,
      pquery:   null,
      firstRow: null,
      result:   {},
      fields:   [],

      controller: null,
      runStart:   null,
      execQuery:  null,
      elapsed:    null,

      saveStatus: null,
    }
  },
  components: {
    VAceEditor,
    Multiselect,
  },
  async mounted() {
    await this.loadQueries()
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  methods: {
    setDirty() {
      this.dirtyEditor = true;
    },
    async loadQueries() {
      this.queries = (await axios.get(`/v3/queries`, {params: {}})).data.data
    },
    queryChanged() {
      if (this.pquery) this.pquery.sql = this.content
      this.content   = this.query.sql || ''
      this.pquery    = this.query
      this.result    = {}
      this.firstRow  = null
      this.execQuery = null
    },

    elapseTime() {
      if (!this.controller) return
      this.elapsed = (dayjs().diff(this.runStart) / 1000).toFixed(1)
      setTimeout(this.elapseTime, 0.1)
    },

    async run() {
      let params  = Object.assign({query: this.content, format: 'hash'}, JSON.parse(this.params))

      this.controller = new AbortController()
      this.runStart   = dayjs()
      this.execQuery  = null
      this.result     = {}
      this.elapseTime()

      let errorR     = {}
      let opts       = {signal: this.controller.signal}
      this.result    = (await axios.post(`/v3/internal/query/${this.backend}`, params, opts)
        .catch((e) => errorR = e.response?.data))?.data || errorR
      this.execQuery = this.result?.meta?.query
      this.firstRow  = this.result?.data ? this.result?.data[0] || {} : {}
      this.fields    = Object.keys(this.firstRow).map((k) => {
        return {value: k, text: `${k} (${typeof this.firstRow[k]})`}
      })
      this.cancel()
    },
    async save() {
      this.query.sql = this.content
      await axios.post(`/v3/queries/${this.query.id}`, {sql: this.query.sql})
      this.saveStatus = 'Saved'
      setTimeout(() => this.saveStatus = null, 3*1000)
    },
    cancel() {
      this.finish()
      if (!this.controller) return
      this.controller.abort()
      this.controller = null
    },
    finish() {
      this.runStart = null
    },

    // initEditor() {
    //   require('brace/ext/language_tools')
    //   require('brace/mode/sql')
    //   require('brace/mode/json')
    //   require('brace/theme/chrome')
    // },
    confirmStayInDirtyEditor() {
      return !window.confirm("Do you really want to leave the page?");
    },
    beforeWindowUnload(event) {
      if (this.dirtyEditor && this.confirmStayInDirtyEditor()) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
  },
}
</script>

<template>
  <div class="w-100">
    <div class='row align-items-center'>
      <div class='col-sm-12'>
        <div class=row>
          <div class=col-6>
            <label class="mt-2 form-label"> Query </label>
            <multiselect :options=queries v-model=query track-by=id label=identifier @select=queryChanged @remove=queryChanged :showLabels=false :allow-empty=false :searchable=true />

            <button @click=run  :disabled=runStart class='btn btn-secondary'>Run</button>
            <select v-model=backend class='form-select w-auto ms-1 d-inline-block' >
              <option value=db >Postgresql</option>
              <option value=bq >Bigquery</option>
            </select>
            <span v-if=controller>
              running for {{ elapsed }} seconds
              <button @click=cancel class='btn btn-secondary'>Cancel</button>
            </span>

            <span class=float-end>
              <span class=mx-3>{{saveStatus}}</span>
              <button @click=save class='btn btn-secondary'>Save</button>
            </span>
          </div>

          <div class=col-6>
            <label class="mt-2 form-label"> Parameters JSON </label>
            <VAceEditor v-model:value=params lang=json theme="chrome" style="height: 150px;" :options=paramsEditor.options ></VAceEditor>
          </div>
        </div>

        <label class="form-label"> Query </label>
        <div class=row style='height: 800px'>
          <div class=col-6><VAceEditor v-model:value=content lang=sql theme="chrome" style="height: 800px;" :options=editor.options @input="setDirty"></VAceEditor></div>

          <div class="col-6 h-100">
            <data-table class="thead-light" v-if=result.data :items=result.data :headers=fields :rows-per-page=result.data.length fixed-header=true table-height=760>
              <!-- this is to render big objects properly -->
              <template v-for="col in fields" #[`item-${col.value}`]="item" :key="col.value">
                <div class="p-3"> {{ item[col.value] }} </div>
              </template>
            </data-table>

            <span v-if=result.error> {{ result.error }} </span>
            <span v-if=!firstRow> empty result </span>
          </div>
        </div>

        <div class=row v-if=execQuery>
          Executed query in {{elapsed}} seconds:
          <textarea class=form-control rows=30 readonly :value=execQuery />
        </div>

      </div>
    </div>
  </div>
</template>
