<script>
import axios from 'axios'
import Swal from "sweetalert2";

export default{
  data() {
    return {
      editedFilter: {},
      columns: ['id', 'title', 'actions'],
      title: ""
    }
  },
  props: ['filter', 'allgroups', 'contract_id', 'modals'],
  created () {
    this.editedFilter = Object.assign({}, this.filter)
  },
  watch: {
    filter: function () {
      this.title = this.filter.title.repeat(1);
    }
  },
  methods: {
    stopEdition () {
      this.modals.editFilter = false;
    },
    async save () {
      if (!/\S/.test(this.title)) {
        return Swal.fire(this.$t('customer_filters.warning'), this.$t('customer_filters.give_a_filter_title'), "warning");
      }
      if (this.filter.id) {
        if (this.editedFilter.title != this.title) {
          let response = await axios.post(`/v3/customer_filters/${this.filter.id}`, { title: this.title }).catch(this.error)
          if (!response || !response.data) return
          this.filter.title = this.title
          this.$emit('filterChanged', response.data.data)
        }
      }
      else {
        let response = await axios.put(
          '/v3/customer_filters',
          { title: this.title, contract_id: this.contract_id },
          { headers: { "Content-Type": "application/json" } }
        ).catch(this.error)
        if (!response || !response.data) return
        this.$emit('filterChanged', response.data.data)
      }
      Swal.fire(this.$t('customer_filters.done'), this.$t('customer_filters.filter_successfully_created'), "success");
      this.stopEdition()
    },
    error (error) {
      return Swal.fire(this.$t('general.error'), this.$t('general.contact_support') + JSON.stringify(error.response.data), 'error');
    },
  }
}
</script>

<template>
  <div>
    <div class="form-row p-3">
      <div class="col-12">
        <label>{{ $t('customer_filters.filter_title') }}</label>
        <b-input-group>
          <b-form-input class="pe-2 border-0 rounded font-size-20 position-relative" v-model="title" :placeholder="$t('customer_filters.give_a_filter_title')"></b-form-input>
          <i class="mt-1 mdi mdi-pencil mdi-24px position-absolute" style="top:0;right:7px;"></i>
        </b-input-group>
      </div>
      <div class="col-12 mt-4 d-flex justify-content-between">
        <button type="submit" class="btn btn-primary" @click='save'>{{ $t('customer_filters.save_filter') }}</button>
        <button type="submit" class="btn btn-secondary ms-3" @click='stopEdition'>{{ $t('customer_filters.cancel_edition') }}</button>
      </div>
    </div>
  </div>
</template>
